import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import teamMember from './slices/teamMemberSlice';
import branch from './slices/branchSlice';
import profile from './slices/profileSlice';
import subscription from './slices/subscription';
import lead from './slices/leadSlice'

const rootReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		theme,
		auth,
		teamMember,
		branch,
		profile,
		subscription,
		lead,
		...asyncReducers,
	});
	return combinedReducer(state, action);
};

export default rootReducer;
