import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'api/axios';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
};

export const getAllBranch = createAsyncThunk('branch/getAllBranch', async (filter, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(`/branches/list?sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`, {});
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const branchSlice = createSlice({
	name: 'branch',
	initialState,
	reducers: {
		showLoading: (state) => {
			state.loading = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllBranch.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBranch.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllBranch.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			});
	},
});

export const { showLoading } = branchSlice.actions;

export default branchSlice.reducer;
